import React from 'react'
import { Radio } from 'antd'
import { Question, Error } from '../../../styles'

export const RadioGroup = props => {
  const { label, id, required, options, value, error, image } = props

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px'
  }

  return (
    <div className="form-group">
      <Question htmlFor={id}>{label}</Question>
      <div className="row">
        <div className="col-5">
          <div className="input-group ml-md-1">
            <Radio.Group
              key={id}
              name={id}
              value={value}
              required={required}
              onChange={e => props.handelChange(e, id)}
            >
              {options.map((o, index) => {
                return (
                  <div key={index}>
                    <Radio style={radioStyle} value={o.value}>
                      {o.label}
                    </Radio>
                  </div>
                )
              })}
            </Radio.Group>
          </div>
        </div>
        {image && <div className="col-7">{image}</div>}
      </div>

      {error && <Error>{error}</Error>}
    </div>
  )
}

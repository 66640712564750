import React from 'react'
import { withRouter } from 'react-router-dom'
import { Login } from './ui/Login'
import Dashboard from './ui/Dashboard'

class AdminContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoggedIn: false,
      isValid: false,
      statusCode: null
    }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(api, form) {
    const { username = '', password = '' } = form

    fetch(`/.netlify/functions/${api}?username=${username}&password=${password}`)
      .then(response => response.json())
      .then(res => {
        this.setState({ loading: false, isValid: res.isValid, statusCode: res.statusCode })
      })
  }

  render() {
    const { brandConfig } = this.props
    const { isValid, statusCode } = this.state

    const isLoggedIn = isValid && statusCode === 200

    return (
      <div className="container">
        <div className="row justify-content-md-center  mb-2 mt-md-3">
          <div className="col-md-3">
            <img
              className="img-fluid"
              src={require(`../../images/logos/${brandConfig.logo}`)}
              alt={brandConfig.title}
            />
          </div>
        </div>

        <div className="row justify-content-md-center mt-md-3">
          {/* <Login handleClick={this.handleClick} {...this.state} /> */}
          {!isLoggedIn ? <Login handleClick={this.handleClick} {...this.state} /> : <Dashboard />}
        </div>
      </div>
    )
  }
}

export default withRouter(AdminContainer)

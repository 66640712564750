export const brandConfig = (brand, isArchived) => {
  if (isArchived) {
    return archived_brandDetails.find(b => {
      let hasBrand = false
      if (b.id === brand) {
        hasBrand = true
      }
      return hasBrand
    })
  } else {
    return brandDetails.find(b => {
      let hasBrand = false
      if (b.id === brand) {
        hasBrand = true
      }
      return hasBrand
    })
  }
}

export const brandConfigByName = brand => {
  return brandDetails.find(b => {
    let hasBrand = false
    if (b.brand === brand) {
      hasBrand = true
    }
    return hasBrand
  })
}

export const brandDetails = [
  {
    id: 'clicks_2021',
    brand: 'clicks_2021',
    title: 'Clicks 2021',
    formId: 101,
    logo: 'clicks.png',
    url: '/clicks_2021'
  }
]

export const archived_brandDetails = [
  {
    id: 'gnc_climb',
    brand: 'gnc_climb',
    title: 'GNC Climb',
    formId: 12,
    logo: 'gnc_climb.jpg',
    url: '/gnc_climb'
  },
  {
    id: 'clicks_2019',
    brand: 'clicks_2019',
    title: 'Clicks 2019',
    formId: 1,
    logo: 'clicks.png',
    url: '/clicks_2019'
  },
  {
    id: 'clicks',
    brand: 'clicks',
    title: 'SAPC',
    formId: 1,
    logo: 'clicks.png',
    url: '/clicks'
  },
  {
    id: 'udok',
    brand: 'udok',
    title: 'UDOK',
    formId: 2,
    logo: 'udok.png',
    url: '/udok'
  },
  {
    id: 'gnc',
    brand: 'gnc',
    title: 'GNC',
    formId: 3,
    logo: 'gnc.jpg',
    url: '/gnc'
  },
  {
    id: 'clubcard',
    brand: 'clubcard',
    title: 'ClubCard',
    formId: 4,
    logo: 'clubcard.jpg',
    url: '/clubcard'
  },
  {
    id: 'expertdayone',
    brand: 'expert',
    title: 'Expert day one',
    formId: 5,
    logo: 'expert.png',
    url: '/expertdayone'
  },
  {
    id: 'expertdaytwo',
    brand: 'expert',
    title: 'Expert day two',
    formId: 6,
    logo: 'expert.png',
    url: '/expertdaytwo'
  },
  {
    id: 'expertdaythree',
    brand: 'expert',
    title: 'Expert day three',
    formId: 7,
    logo: 'expert.png',
    url: '/expertdaythree'
  },
  {
    id: 'unicorn',
    brand: 'unicorn',
    title: 'UNICORN',
    formId: 8,
    logo: 'unicorn.jpg',
    url: '/unicorn'
  },
  {
    id: 'upd',
    brand: 'upd',
    title: 'UPD',
    formId: 9,
    logo: 'upd.jpg',
    url: '/upd'
  },
  {
    id: 'ewp',
    brand: 'ewp',
    title: 'EWP',
    formId: 10,
    logo: 'ewp.jpg',
    url: '/ewp'
  },
  {
    id: 'engen',
    brand: 'engen',
    title: 'Engen',
    formId: 11,
    logo: 'clicks.png',
    images: ['one.jpg', 'two.jpg'],
    url: '/engen'
  }
]

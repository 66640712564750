import React from 'react'
import { Error } from '../../../styles'

export const SelectField = props => {
  const { label, id, type, required, value, error, options, hidden } = props

  return (
    <div className="form-group" hidden={hidden ? hidden : false}>
      <label htmlFor={id}>{label}</label>
      <select
        className={error ? 'form-control input-lg is-invalid' : 'form-control input-lg'}
        key={id}
        name={id}
        id={id}
        type={type}
        onChange={e => props.handelChange(e, id)}
        value={value}
        required={required}
      >
        {options.map(o => (
          <option value={o}>{o}</option>
        ))}
      </select>

      {error && <Error>{error}</Error>}
    </div>
  )
}

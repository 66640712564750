import React from 'react'

import { brandDetails, archived_brandDetails } from '../../common/configuration/brandConfig'
import { Link } from 'react-router-dom'

class ListContainer extends React.Component {
  render() {
    return (
      <div className="container">
        <div className="row justify-content-md-center mb-4 mt-md-3">
          <div className="col-md-4">
            <img
              className="img-fluid"
              src={require(`../../images/logos/clicks.png`)}
              alt={'list'}
            />
          </div>
        </div>
        <div className="col-6">
          <label>
            <strong>Available pages</strong>
          </label>
          <ul>
            {brandDetails.map(b => (
              <li>
                <Link to={b.url}>{b.title}</Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="col-6">
          <label>
            <strong>Archived pages</strong>
          </label>
          <ul>
            {archived_brandDetails.map(b => {
              console.log("2222", b.url)
              return (
                <li>
                  <Link to={`archived${b.url}`}>{b.title}</Link>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    )
  }
}

export default ListContainer

import React from 'react'
import { Error } from '../../../styles'

export const Checkbox = props => {
  const { label, id, required, error, hidden } = props

  return (
    <div className="form-group">
      <div className="form-check" hidden={hidden ? hidden : false}>
        <input
          className="form-check-input"
          key={id}
          name={id}
          id={id}
          type="checkbox"
          onChange={e => props.handelChange(e, id)}
          required={required}
        />
        <label className="form-check-label" htmlFor={id}>
          {label}
        </label>

        {error && <Error>{error}</Error>}
      </div>
    </div>
  )
}

import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import * as serviceWorker from './serviceWorker'
import { ApolloProvider } from 'react-apollo'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { BrowserRouter as Router } from 'react-router-dom'

import App from './App'

import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`
})

const client = new ApolloClient({
  link: httpLink,
  onError: ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      console.log(graphQLErrors)
    }
  },
  cache: new InMemoryCache()
})

ReactDOM.render(
  <ApolloProvider client={client}>
    <Router>
      <App />
    </Router>
  </ApolloProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

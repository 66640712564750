import styled from 'styled-components'

const textColor = '#404040'

const Question = styled.h6``

const StepTitle = styled.h3`
  color: ${textColor};
`

const PageTitle = styled.h1`
  color: ${textColor};
`

const Error = styled.div`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
`

const Logo = styled.div`
  height: 80px;
`

export { PageTitle, StepTitle, Question, Error, Logo }

import React from 'react'
import { InputField } from '../../Form/ui/fields/InputField'
import { Button } from 'antd'

export class Login extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      form: {}
    }

    this.handelChange = this.handelChange.bind(this)
  }

  handelChange(event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    const { form } = this.state

    form[name] = value

    this.setState({ form })
  }

  handleClick = api => e => {
    e.preventDefault()

    const { username = '', password = '' } = this.state.form

    this.setState({ loading: true })
    fetch(`/.netlify/functions/${api}?username=${username}&password=${password}`)
      .then(response => response.json())
      .then(res => this.setState({ loading: false, isValid: res }))
  }

  render() {
    const { loading, form } = this.state
    const { isValid, statusCode } = this.props

    return (
      <div className="container">
        <div className="row justify-content-md-center pt-md-2">
          <div className="col-md-5 ">
            <div className="card bg-light mb-3">
              <div class="card-body">
                <div className="mb-4">
                  <div className="mb-4 text-center m-auto">
                    <strong>
                      <label>Login</label>
                    </strong>
                  </div>

                  <InputField
                    label={'Username'}
                    handelChange={this.handelChange}
                    id={'username'}
                    type={'text'}
                    required={true}
                  />
                  <InputField
                    label={'Password'}
                    handelChange={this.handelChange}
                    id={'password'}
                    type={'password'}
                    required={true}
                  />
                  <div className="row justify-content-md-center mt-md-3">
                    <Button type="default" onClick={() => this.props.handleClick('login', form)}>
                      {loading ? 'Loading...' : 'Login'}
                    </Button>
                  </div>
                  <div className="mt-3">
                    {!isValid && statusCode === 401 ? (
                      <div className="alert alert-danger" role="alert">
                        Incorrect login details
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

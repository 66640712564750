import React from 'react'
import { Error } from '../../../styles'

export const InputField = props => {
  const { label, id, type, required, value, error, hidden } = props

  return (
    <div className="form-group" hidden={hidden ? hidden : false}>
      <label htmlFor={id}>{label}</label>
      <input
        className={error ? 'form-control input-lg is-invalid' : 'form-control input-lg'}
        key={id}
        name={id}
        id={id}
        type={type}
        onChange={e => props.handelChange(e, id)}
        value={value}
        required={required}
      />
      {/* {id === 'email' && (
        <small id="emailHelp" class="form-text text-muted">
          We'll never share your email with anyone else.
        </small>
      )} */}

      {error && <Error>{error}</Error>}
    </div>
  )
}

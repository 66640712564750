import React from 'react'
import { Steps } from 'antd'
import { forms, archived_forms } from '../../common/api/forms'
import FormContainer from '../Form/FormContainer'

const Step = Steps.Step

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

class PageContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      current: 0,
      errors: {},
      error: null,
      loading: false,
      disabled: false,
      success: null,
      form: {},
      msg: null
    }

    this.handelChange = this.handelChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  handelChange(event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    const { form } = this.state

    form[name] = value

    this.setState({ form })
  }

  onSubmit = async e => {
    const { name = '', surname = '', location = '', phone = '', email = '' } = this.state.form

    const {
      brandConfig: { brand }
    } = this.props

    let submission = {
      name,
      surname,
      phone,
      location,
      brand: brand ? brand : 'clicks',
      email
    }

    this.setState({
      loading: true,
      error: null
    })

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact-version-2', ...submission })
    })
      .then(response => {
        if (!response.ok) {
          this.setState({
            loading: false,
            error: 'An unexpected error has occurred. Please try again.'
          })
          throw Error(response.statusText)
        }

        return response
      })
      .then(response => {
        this.setState({
          loading: false,
          success: 'Thank you for entering our competition! Winners will be contacted via phone or email.',
          current: 0,
          errors: {},
          disabled: false,
          form: {}
        })
        setTimeout(() => {
          this.setState({ success: null })
        }, 5000)
      })
      .catch(error => {
        this.setState({
          loading: false,
          error: 'An unexpected error has occurred. Please try again.'
        })
      })
  }

  validate(question) {
    const { fields, answer } = question
    let errors = {}

    const inputValues = this.state.form

    fields.forEach(field => {
      const { id, required } = field

      if (required && !inputValues[id]) {
        errors[id] = 'Required Field'
      } else if (id === 'email') {
        // eslint-disable-next-line no-useless-escape
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        let isEmail = re.test(String(inputValues[id]).toLowerCase())
        if (!isEmail) {
          errors[id] = 'Invalid email address'
        }
      } else if (answer && inputValues[id] !== answer) {
        errors[id] = 'Incorrect answer. Try again.'
      }
    })

    if (Object.keys(errors).length) {
      this.setState({ errors: errors })
      return true
    } else {
      this.setState({ errors: {} })
      return false
    }
  }

  next() {
    const current = this.state.current + 1
    this.setState({ current })
  }

  prev() {
    const current = this.state.current - 1
    this.setState({ current })
  }

  render() {
    const { current, errors, success, loading, error } = this.state
    const { brandConfig, isArchived } = this.props

    

    let form
    const logo = brandConfig.images ? brandConfig.images[current] : brandConfig.logo

    if (isArchived) {
      form = archived_forms.find(f => f.id === brandConfig.formId)
    } else {
      form = forms.find(f => f.id === brandConfig.formId)
    }

    return (
      <div className="container">
        <div className="row justify-content-md-center pt-md-4 ">
          <div className="col-md-6">
            <center>
              <img
                className="img-fluid"
                src={require(`../../images/logos/${logo}`)}
                alt={brandConfig.title}
              />
            </center>
          </div>
        </div>
        <div className="row justify-content-md-center pt-md-4">
          <div className="col-md-6 pt-md-1">
            <div className="card bg-light mb-3">
              <div class="card-body">
                <div className="mb-4">
                  <Steps current={current}>
                    {form.questions.map((item, index) => (
                      <Step key={index} />
                    ))}
                  </Steps>
                </div>
                {error ? (
                  <div className="alert alert-danger" role="alert">
                    {error}
                  </div>
                ) : null}
                {success ? (
                  <div className="alert alert-success" role="alert">
                    {success}
                  </div>
                ) : null}
                {loading ? (
                  <div className="alert alert-success" role="alert">
                    Submitting
                  </div>
                ) : (
                  <FormContainer
                    question={current}
                    handelChange={this.handelChange}
                    values={this.state.form}
                    errors={errors}
                    questions={form.questions}
                  >
                    {current < form.questions.length - 1 && (
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={() => {
                          const hasErrors = this.validate(form.questions[current])
                          if (hasErrors) {
                            return
                          }
                          this.next()
                        }}
                      >
                        Next
                      </button>
                    )}

                    {current === form.questions.length - 1 && (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          const hasErrors = this.validate(form.questions[current])
                          if (hasErrors) {
                            return
                          }
                          this.onSubmit()
                        }}
                      >
                        Submit
                      </button>
                    )}
                    {current > 0 && (
                      <button
                        type="button"
                        className="btn btn-link"
                        style={{ marginRight: 8 }}
                        onClick={() => {
                          this.setState({ errors: {} })
                          this.prev()
                        }}
                      >
                        Previous
                      </button>
                    )}
                  </FormContainer>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-md-center"></div>
      </div>
    )
  }
}

export default PageContainer

import React from 'react'
import './App.css'
import './index.css'
import { Route, Switch, Link } from 'react-router-dom'
import { brandConfig, brandConfigByName } from './common/configuration/brandConfig'
import AdminContainer from './modules/Admin/AdminContainer'
import ListContainer from './modules/list/ListContainer'

import PageContainer from './modules/Page/PageContainer'

const PageNotFound = () => (
  <div className="text-center m-auto">
    <h3>404 - Page not found.</h3>
    <p>Oops, the page you are looking for does not exist.</p>
    <p>Please make sure that you have entered the correct address.</p>
    <Link to={'/list'}>Click here for a list of available pages</Link>
  </div>
)

const App = () => {
  return (
    <div className="App">
      <Switch>
        <Route
          path="/"
          exact
          render={routerProps => {
            return <ListContainer {...routerProps} />
          }}
        />
        <Route
          path="/list"
          exact
          render={routerProps => {
            return <ListContainer {...routerProps} />
          }}
        />
        <Route
          path="/archived/:brandId"
          exact
          render={routerProps => {
            const { brandId } = routerProps.match.params

            const config = brandConfig(brandId, true)

            if (!config) {
              return <PageNotFound />
            }

            return <PageContainer {...routerProps} brandConfig={config} isArchived />
          }}
        />
        <Route
          path="/archived/:brandId/admin"
          exact
          render={routerProps => {
            const { brandId } = routerProps.match.params
            const config = brandConfig(brandId, true)
            if (!config) {
              return <PageNotFound />
            }
            return <AdminContainer brandConfig={config} />
          }}
        />

        <Route
          path="/:brandId/admin"
          exact
          render={routerProps => {
            const { brandId } = routerProps.match.params
            const config = brandConfigByName(brandId)
            if (!config) {
              return <PageNotFound />
            }
            return <AdminContainer brandConfig={config} />
          }}
        />
        <Route
          path="/:brandId"
          render={routerProps => {
            const { brandId } = routerProps.match.params

            const config = brandConfig(brandId)

            if (!config) {
              return <PageNotFound />
            }

            return <PageContainer {...routerProps} brandConfig={config} />
          }}
        />

        <Route
          render={routerProps => {
            return <ListContainer {...routerProps} />
          }}
        />
      </Switch>
    </div>
  )
}

export default App

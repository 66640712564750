import React from 'react'
import { Button } from 'antd'
import { withRouter } from 'react-router-dom'
import { CSVLink } from 'react-csv'

class Dashboard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      winnerList: [],
      loading: false,
      entries: [],
      loadingCsv: false
    }
  }

  handleClick = api => e => {
    e.preventDefault()

    const { brandId } = this.props.match.params

    this.setState({ loading: true })
    fetch(`/.netlify/functions/${api}?brand=${brandId}`)
      .then(response => response.json())
      .then(json => this.setState({ loading: false, winnerList: json.winnerList }))
  }

  handleCsvDownload = e => {
    const { brandId } = this.props.match.params
    this.setState({ loadingCsv: true })

    fetch(`/.netlify/functions/csvData?brand=${brandId}`)
      .then(response => response.json())
      .then(json => {
        this.setState({ loadingCsv: false, entries: json.entries }, () => {
          this.csvLink.current.link.click()
        })
      })
  }

  csvLink = React.createRef()

  render() {
    const { winnerList, loading, entries, loadingCsv } = this.state
    const { brandId } = this.props.match.params

   

    return (
      <div className="container">
        <div className="row justify-content-md-center mt-md-3">
          <Button type="default" onClick={this.handleClick('generateWinnerList')} className="mr-3">
            {loading ? 'Loading...' : ' Generate randomised winner list'}
          </Button>
          <div>
            <Button type="default" onClick={() => this.handleCsvDownload()}>
              {loadingCsv ? 'Loading...' : 'Download submissions'}
            </Button>
            <CSVLink
              data={entries}
              filename={`entries_${brandId}.csv`}
              className="hidden"
              ref={this.csvLink}
              target="_blank"
            />
          </div>
        </div>
        <div className="row justify-content-md-center mt-md-3">
          <div className="container">
            {winnerList.length > 0 && (
              <div>
                {winnerList.map((w, index) => {
                  return (
                    <>
                      <div className="row">
                        <div className="col-md-1">{index + 1}</div>
                        <div className="col">{w.name}</div>
                        <div className="col">{w.phone}</div>
                        <div className="col">
                          <a href={`mailto:${w.email}?subject=Congratulations!`}>{w.email}</a>
                        </div>
                      </div>
                      <hr />
                    </>
                  )
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}
export default withRouter(Dashboard)

import React from 'react'
import { InputField } from './ui/fields/InputField'
import { RadioGroup } from './ui/fields/RadioGroup'
import { Checkbox } from './ui/fields/Checkbox'
import { SelectField } from './ui/fields/Select'

class FormContainer extends React.Component {
  render() {
    const { values, errors, handelChange, questions, question } = this.props

    const q = questions.find((q, index) => index === question)

    if (!q) return null

    return (
      <div>
        <form onSubmit={this.props.onSubmit} name="contact-version-2" dataNetlify="true">
          {q.fields.map((f, index) => {
            const { value = '' } = f

            if (f.type === 'select') {
              return (
                <SelectField
                  key={index}
                  {...f}
                  handelChange={handelChange}
                  value={values[f.id]}
                  error={errors[f.id]}
                />
              )
            } else if (f.type === 'radio') {
              return (
                <RadioGroup
                  hidden={question !== index}
                  key={index}
                  {...f}
                  handelChange={handelChange}
                  value={values[f.id]}
                  error={errors[f.id]}
                />
              )
            } else if (f.type === 'checkbox') {
              return (
                <Checkbox key={index} {...f} handelChange={handelChange} error={errors[f.id]} />
              )
            }

            return (
              <InputField
                key={index}
                {...f}
                value={values[f.id] || value}
                error={errors[f.id]}
                handelChange={handelChange}
                hidden={f.hidden}
              />
            )
          })}
          {this.props.children}
        </form>
      </div>
    )
  }
}

export default FormContainer

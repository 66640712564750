import React from 'react'

export const forms = [{
  name: 'clicks_2021',
  id: 101,
  questions: [
    {
      title: 'Personal Information',
      fields: [
        {
          id: 'name',
          label: 'Name',
          type: 'text',
          required: true,
          options: []
        },
        {
          id: 'surname',
          label: 'Surname',
          type: 'text',
          required: true,
          options: []
        },
        {
          id: 'email',
          label: 'Email',
          type: 'email',
          required: true,
          options: []
        },
        {
          id: 'phone',
          label: 'Phone number',
          type: 'text',
          required: true
        }
      ]
    },
    {
      title: '',
      answer: 'beauty',
      fields: [
        {
          id: 'whichArea',
          label: 'Which area of the Clicks store will be relaunching today?',
          type: 'radio',
          required: true,
          options: [
            {
              value: 'health',
              label: 'Health'
            },
            {
              value: 'supplements',
              label: 'Supplements'
            },
            {
              value: 'beauty',
              label: 'Beauty'
            },
            {
              value: 'sports',
              label: 'Sports'
            }
          ]
        }
      ]
    },
    {
      title: '',
      answer: 'yes',
      fields: [
        {
          id: 'shopOnline',
          label:
            'Can you shop online for your favourite beauty products by using the Clicks digital leaflet?',
          type: 'radio',
          required: true,
          options: [
            {
              value: 'no',
              label: 'No'
            },
            {
              value: 'yes',
              label: 'Yes'
            },
            {
              value: 'dontKnow',
              label: ' I don’t know'
            }
            
          ]
        }
      ]
    },
    {
      title: '',
      answer: '24',
      fields: [
        {
          id: 'untilWhen',
          label: 'Until when will our Clicks Beauty Fair promotions be running in store & online?',
          type: 'radio',
          required: true,
          options: [
            {
              value: '11',
              label: '11 December 2021'
            },
            {
              value: '10',
              label: '10 January 2022'
            },
            {
              value: '24',
              label: '24 December 2021'
            },
            {
              value: '1',
              label: '1 February 2022'
            }
          ]
        }
      ]
    },
    {
      title: '',
      answer: 'yes',
      fields: [
        {
          id: 'cashback',
          label: 'Do you earn cashback on your beauty purchases at Clicks?',
          type: 'radio',
          required: true,
          options: [
            {
              value: 'yes',
              label: 'Yes'
            },
            {
              value: 'no',
              label: 'No'
            }
          ]
        }
      ]
    }
  ]
}]

export const archived_forms = [
  {
    name: 'GNC climb',
    id: 12,
    questions: [
      {
        title: 'Your Details',
        fields: [
          {
            id: 'name',
            label: 'Name',
            type: 'text',
            required: true,
            options: []
          },
          {
            id: 'surname',
            label: 'Surname',
            type: 'text',
            required: true,
            options: []
          },
          {
            id: 'phone',
            label: 'Cell number',
            type: 'text',
            required: true
          }
        ]
      },
      {
        title: '',
        answer: 'energy_and_hydration',
        fields: [
          {
            id: 'function',
            label: 'What is the primary function of the GNC Climb range?',
            type: 'radio',
            requires: true,
            options: [
              {
                value: 'energy_and_hydration',
                label: 'Energy, fuel, recovery and hydration'
              },
              {
                value: 'energy',
                label: 'Energy'
              },
              {
                value: 'recovery',
                label: 'Recovery and hydration'
              }
            ]
          }
        ]
      },
      {
        title: '',
        answer: 'yes',
        fields: [
          {
            id: 'substance_porcine',
            label: 'Is GNC Climb banned substance and porcine free?',
            type: 'radio',
            requires: true,
            options: [
              {
                value: 'no',
                label: 'No'
              },
              {
                value: 'yes',
                label: 'Yes'
              },
              {
                value: 'porcine_free',
                label: 'Only porcine free'
              }
            ]
          }
        ]
      },
      {
        title: '',
        answer: 'genetically_modified',
        fields: [
          {
            id: 'gmc_meaning',
            label: 'What does GMO stand for?',
            type: 'radio',
            requires: true,
            options: [
              {
                value: 'generally_organism',
                label: 'Generally modified organism'
              },
              {
                value: 'modified_oats',
                label: 'Genetically modified oats'
              },
              {
                value: 'genetically_modified',
                label: 'Genetically modified organism'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    name: 'udok',
    id: 2,
    questions: [
      {
        title: 'Personal Information',
        fields: [
          {
            id: 'name',
            label: 'Name',
            type: 'text',
            required: true,
            options: []
          },
          {
            id: 'email',
            label: 'Email',
            type: 'email',
            required: true,
            options: []
          },
          {
            id: 'phone',
            label: 'Phone number',
            type: 'text',
            required: true
          }
        ]
      },
      {
        title: '',
        answer: 'telemedicine_consultation',
        fields: [
          {
            id: 'serviceType',
            label: 'What type of service is Udok?',
            type: 'radio',
            required: true,
            options: [
              {
                value: 'telephonic_consultation',
                label: 'Telephonic consultation'
              },
              {
                value: 'telemedicine_consultation',
                label: 'Telemedicine consultation'
              },
              {
                value: 'nurse_consultation',
                label: 'Nurse consultation'
              },
              {
                value: 'pharmacist_consultation',
                label: 'Pharmacist consultation'
              }
            ]
          }
        ]
      },
      {
        title: '',
        answer: '5',
        fields: [
          {
            id: 'udok_count',
            label: 'In how many Clicks Pharmacies is Udok currently available?',
            type: 'radio',
            required: true,
            options: [
              {
                value: '4',
                label: '4'
              },
              {
                value: '5',
                label: '5'
              },
              {
                value: '550',
                label: '550'
              },
              {
                value: '700',
                label: '700'
              }
            ]
          }
        ]
      },
      {
        title: '',
        answer: '285',
        fields: [
          {
            id: 'consultation_cost',
            label: 'How much is a Udok consultation with the doctor?',
            type: 'radio',
            required: true,
            options: [
              {
                value: '500',
                label: 'R500'
              },
              {
                value: '285',
                label: 'R285'
              },
              {
                value: '750',
                label: 'R750'
              },
              {
                value: '100',
                label: 'R100'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    name: 'clicks',
    id: 1,
    questions: [
      {
        title: 'Personal Information',
        fields: [
          {
            id: 'name',
            label: 'Name',
            type: 'text',
            required: true,
            options: []
          },
          {
            id: 'email',
            label: 'Email',
            type: 'email',
            required: true,
            options: []
          },
          {
            id: 'phone',
            label: 'Phone number',
            type: 'text',
            required: true
          },
          {
            id: 'owner',
            label: 'Are you a pharmacy owner?',
            type: 'checkbox',
            required: false,
            options: []
          }
        ]
      },
      {
        title: '',
        answer: '550',
        fields: [
          {
            id: 'howMany',
            label: 'How many Clicks Pharmacies are there nationally?',
            type: 'radio',
            required: true,
            options: [
              {
                value: '700',
                label: '700'
              },
              {
                value: '350',
                label: '350'
              },
              {
                value: '100',
                label: '100'
              },
              {
                value: '550',
                label: '550'
              }
            ]
          }
        ]
      },
      {
        title: '',
        answer: '2026',
        fields: [
          {
            id: 'year',
            label:
              'According to the NHI Memorandum, in which year will we have full implementation of NHI in South Africa?',
            type: 'radio',
            required: true,
            options: [
              {
                value: '2020',
                label: '2020'
              },
              {
                value: '2026',
                label: '2026'
              },
              {
                value: '2030',
                label: '2030'
              },
              {
                value: 'never',
                label: 'Never'
              }
            ]
          }
        ]
      },
      {
        title: '',
        answer: 'zweli',
        fields: [
          {
            id: 'minister',
            label: 'What is the name of our Minister of Health?',
            type: 'radio',
            required: true,
            options: [
              {
                value: 'senzeni',
                label: 'Senzeni Zokwana'
              },
              {
                value: 'vikesh',
                label: 'Vikesh Ramsunder'
              },
              {
                value: 'jan',
                label: 'Jan Roos'
              },
              {
                value: 'zweli',
                label: 'Zweli Mkhize'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    name: 'gnc',
    id: 3,
    questions: [
      {
        title: 'Personal Information',
        fields: [
          {
            id: 'name',
            label: 'Name',
            type: 'text',
            required: true,
            options: []
          },
          {
            id: 'email',
            label: 'Email',
            type: 'email',
            required: true,
            options: []
          },
          {
            id: 'phone',
            label: 'Phone number',
            type: 'text',
            required: true
          }
        ]
      },
      {
        title: '',
        answer: '1935',
        fields: [
          {
            id: 'founded_in',
            label: 'GNC was founded in:',
            type: 'radio',
            required: true,
            options: [
              {
                value: '1935',
                label: '1935'
              },
              {
                value: '1954',
                label: '1954'
              },
              {
                value: '1942',
                label: '1942'
              }
            ]
          }
        ]
      },
      {
        title: '',
        answer: '150',
        fields: [
          {
            id: 'quality_checks',
            label: 'How many quality checks will a GNC product go through to ensure potency?',
            type: 'radio',
            required: true,
            options: [
              {
                value: '100',
                label: '100'
              },
              {
                value: '150',
                label: '150'
              },
              {
                value: '50',
                label: '50'
              }
            ]
          }
        ]
      },
      {
        title: '',
        answer: '55',
        fields: [
          {
            id: 'countries',
            label: 'GNC is in over ... countries:',
            type: 'radio',
            required: true,
            options: [
              {
                value: '34',
                label: '34'
              },
              {
                value: '67',
                label: '67'
              },
              {
                value: '55',
                label: '55'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    name: 'clubcard',
    id: 4,
    questions: [
      {
        title: 'Personal Information',
        fields: [
          {
            id: 'name',
            label: 'Name',
            type: 'text',
            required: true,
            options: []
          },
          {
            id: 'email',
            label: 'Email',
            type: 'email',
            required: true,
            options: []
          },
          {
            id: 'phone',
            label: 'Phone number',
            type: 'text',
            required: true
          }
        ]
      },
      {
        title: '',
        answer: '1995',
        fields: [
          {
            id: 'launched',
            label: 'When was ClubCard launched?',
            type: 'radio',
            required: true,
            options: [
              {
                value: '2010',
                label: '2010'
              },
              {
                value: '1998',
                label: '1998'
              },
              {
                value: '1995',
                label: '1995'
              },
              {
                value: '2005',
                label: '2005'
              }
            ]
          }
        ]
      },
      {
        title: '',
        answer: '8 Million',
        fields: [
          {
            id: 'launched',
            label: 'How many active ClubCard members do we have?',
            type: 'radio',
            required: true,
            options: [
              {
                value: '8 Million',
                label: '8 Million'
              },
              {
                value: '5 Million',
                label: '5 Million'
              },
              {
                value: '25 Million',
                label: '25 Million'
              },
              {
                value: '3 Million',
                label: '3 Million'
              }
            ]
          }
        ]
      },
      {
        title: '',
        answer: 'true',
        fields: [
          {
            id: 'statements',
            label: (
              <div>
                Are all of the following statements true or false?
                <br />
                <br />
                Get ClubCard on your phone and…
                <br />
                <br />
                <ul>
                  <li>Scan your digital card in store. Earn points. Spend your cashback.</li>
                  <li>Check your cashback balance and points activity</li>
                  <li>Submit scripts, see your script history and order repeats</li>
                  <li>Find your nearest Clicks store, pharmacy or clinic</li>
                </ul>
              </div>
            ),
            type: 'radio',
            required: true,
            options: [
              {
                value: 'true',
                label: 'true'
              },
              {
                value: 'false',
                label: 'false'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    name: 'expert_day_one',
    id: 5,
    questions: [
      {
        title: 'Personal Information',
        fields: [
          {
            id: 'name',
            label: 'Name',
            type: 'text',
            required: true,
            options: []
          },
          {
            id: 'email',
            label: 'Email',
            type: 'email',
            required: true,
            options: []
          },
          {
            id: 'phone',
            label: 'Phone number',
            type: 'text',
            required: true
          }
        ]
      },
      {
        title: '',
        answer: 'June 2019',
        fields: [
          {
            id: 'when',
            label: 'When was our NEW brand Clicks Expert launched in store?',
            type: 'radio',
            required: true,
            options: [
              {
                value: 'June 2019',
                label: 'June 2019'
              },
              {
                value: 'June 2018',
                label: 'June 2018'
              },
              {
                value: 'June 2017',
                label: 'June 2017'
              },
              {
                value: 'June 2016',
                label: 'June 2016'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    name: 'expert_day_two',
    id: 6,
    questions: [
      {
        title: 'Personal Information',
        fields: [
          {
            id: 'name',
            label: 'Name',
            type: 'text',
            required: true,
            options: []
          },
          {
            id: 'email',
            label: 'Email',
            type: 'email',
            required: true,
            options: []
          },
          {
            id: 'phone',
            label: 'Phone number',
            type: 'text',
            required: true
          }
        ]
      },
      {
        title: '',
        answer: 'Animal-Tested',
        fields: [
          {
            id: 'statements',
            label: 'Which answer is FALSE with the following Statement? Clicks Expert is….',
            type: 'radio',
            required: true,
            options: [
              {
                value: 'Paraben-Free',
                label: 'Paraben-Free'
              },
              {
                value: 'Animal-Tested',
                label: 'Animal-Tested'
              },
              {
                value: 'Sulphate-Free',
                label: 'Sulphate-Free'
              },
              {
                value: 'Fragrance-Free',
                label: 'Fragrance-Free'
              },
              {
                value: 'Clinically-Tested',
                label: 'Clinically-Tested'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    name: 'expert_day_three',
    id: 7,
    questions: [
      {
        title: 'Personal Information',
        fields: [
          {
            id: 'name',
            label: 'Name',
            type: 'text',
            required: true,
            options: []
          },
          {
            id: 'email',
            label: 'Email',
            type: 'email',
            required: true,
            options: []
          },
          {
            id: 'phone',
            label: 'Phone number',
            type: 'text',
            required: true
          }
        ]
      },
      {
        title: '',
        answer: '5',
        fields: [
          {
            id: 'skin_scenarios',
            label: 'How many skin scenarios can Clicks Expert assist with?',
            type: 'radio',
            required: true,
            options: [
              {
                value: '5',
                label: '5'
              },
              {
                value: '3',
                label: '3'
              },
              {
                value: '1',
                label: '1'
              },
              {
                value: 'None of the above',
                label: 'None of the above'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    name: 'unicorn',
    id: 8,
    questions: [
      {
        title: 'Personal Information',
        fields: [
          {
            id: 'name',
            label: 'Name',
            type: 'text',
            required: true,
            options: []
          },
          {
            id: 'email',
            label: 'Email',
            type: 'email',
            required: true,
            options: []
          },
          {
            id: 'phone',
            label: 'Phone number',
            type: 'text',
            required: true
          }
        ]
      },
      {
        title: '',
        answer: 'lamotrigine',
        fields: [
          {
            id: 'when',
            label: 'Match molecule with the image',
            image: (
              <img
                className="img-fluid"
                src={require(`../../images/questions/serinem.png`)}
                alt={require(`../../images/logos/clicks.png`)}
              />
            ),
            type: 'radio',
            required: true,
            options: [
              {
                value: 'zolpidem',
                label: 'Zolpidem'
              },
              {
                value: 'lamotrigine',
                label: 'Lamotrigine'
              },
              {
                value: 'valaciclovir',
                label: 'Valaciclovir'
              },
              {
                value: 'quetiapine',
                label: 'Quetiapine'
              }
            ]
          }
        ]
      },
      {
        title: '',
        answer: 'topiramate',
        fields: [
          {
            id: 'topiramate',
            label: 'Match molecule with the image',
            image: (
              <img
                className="img-fluid"
                src={require(`../../images/questions/pamiram.png`)}
                alt={require(`../../images/logos/clicks.png`)}
              />
            ),
            type: 'radio',
            required: true,
            options: [
              {
                value: 'indapamide',
                label: 'Indapamide'
              },
              {
                value: 'losartan_potassium',
                label: 'Losartan potassium'
              },
              {
                value: 'quetiapine',
                label: 'Quetiapine'
              },
              {
                value: 'topiramate',
                label: 'Topiramate'
              }
            ]
          }
        ]
      },
      {
        title: '',
        answer: 'olanzapine',
        fields: [
          {
            id: 'olanzapine',
            image: (
              <img
                className="img-fluid"
                src={require(`../../images/questions/zylena.png`)}
                alt={require(`../../images/logos/clicks.png`)}
              />
            ),
            label: 'Match molecule with the image',
            type: 'radio',
            required: true,
            options: [
              {
                value: 'topiramate',
                label: 'Topiramate'
              },
              {
                value: 'losartan_potassium',
                label: 'Losartan potassium'
              },
              {
                value: 'olanzapine',
                label: 'Olanzapine'
              },
              {
                value: 'dorzolamide_timolol',
                label: 'Dorzolamide/Timolol'
              }
            ]
          }
        ]
      },
      {
        title: '',
        answer: 'indapamide',
        fields: [
          {
            id: 'indapamide',
            label: 'Match molecule with the image',
            image: (
              <img
                className="img-fluid"
                src={require(`../../images/questions/catexan.png`)}
                alt={require(`../../images/logos/clicks.png`)}
              />
            ),
            type: 'radio',
            required: true,
            options: [
              {
                value: 'indapamide',
                label: 'Indapamide'
              },
              {
                value: 'valaciclovir',
                label: 'Valaciclovir'
              },
              {
                value: 'quetiapine',
                label: 'Quetiapine'
              },
              {
                value: 'topiramate',
                label: 'Topiramate'
              }
            ]
          }
        ]
      },
      {
        title: '',
        answer: 'valaciclovir',
        fields: [
          {
            id: 'valaciclovir',
            label: 'Match molecule with the image',
            image: (
              <img
                className="img-fluid"
                src={require(`../../images/questions/valatrex.png`)}
                alt={require(`../../images/logos/clicks.png`)}
              />
            ),
            type: 'radio',
            required: true,
            options: [
              {
                value: 'indapamide',
                label: 'Indapamide'
              },
              {
                value: 'zolpidem',
                label: 'Zolpidem'
              },
              {
                value: 'valaciclovir',
                label: 'Valaciclovir'
              },
              {
                value: 'quetiapine',
                label: 'Quetiapine'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    name: 'upd',
    id: 9,
    questions: [
      {
        title: 'Personal Information',
        fields: [
          {
            id: 'name',
            label: 'Name',
            type: 'text',
            required: true,
            options: []
          },
          {
            id: 'email',
            label: 'Email',
            type: 'email',
            required: true,
            options: []
          },
          {
            id: 'phone',
            label: 'Phone number',
            type: 'text',
            required: true
          }
        ]
      },
      {
        title: '',
        answer: '21500',
        fields: [
          {
            id: 'kilometers',
            label: 'How many kilometers per day does UPD travel to service its customer base?',
            type: 'radio',
            required: true,
            options: [
              {
                value: '50000',
                label: '50 000km a day'
              },
              {
                value: '19000',
                label: '19 000km a day'
              },
              {
                value: '21500',
                label: '21 500km a day'
              },
              {
                value: '10000',
                label: '10 000km a day'
              }
            ]
          }
        ]
      },
      {
        title: '',
        answer: '20000',
        fields: [
          {
            id: 'parcels',
            label: 'How many parcels does UPD pick nationally and daily?',
            type: 'radio',
            required: true,
            options: [
              {
                value: '20000',
                label: '20 000'
              },
              {
                value: '30000',
                label: '30 000'
              },
              {
                value: '25000',
                label: '25 000'
              },
              {
                value: '15000',
                label: '15 000'
              }
            ]
          }
        ]
      },
      {
        title: '',
        answer: '5',
        fields: [
          {
            id: 'parcels',
            label: 'How many branches does UPD have?',
            type: 'radio',
            required: true,
            options: [
              {
                value: '1',
                label: '1'
              },
              {
                value: '2',
                label: '2'
              },
              {
                value: '5',
                label: '5'
              },
              {
                value: '4',
                label: '4'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    name: 'ewp',
    id: 10,
    questions: [
      {
        title: 'Personal Information',
        fields: [
          {
            id: 'name',
            label: 'Name',
            type: 'text',
            required: true,
            options: []
          },
          {
            id: 'email',
            label: 'Email',
            type: 'email',
            required: true,
            options: []
          },
          {
            id: 'phone',
            label: 'Phone number',
            type: 'text',
            required: true
          }
        ]
      },
      {
        title: '',
        answer: '0800 611 102',
        fields: [
          {
            id: 'kilometers',
            label: 'What is the EWP Toll free number',
            type: 'radio',
            required: true,
            options: [
              {
                value: '0800 116 201',
                label: '0800 116 201'
              },
              {
                value: '0800 511 366',
                label: '0800 511 366'
              },
              {
                value: '0800 611 102',
                label: '0800 611 102'
              }
            ]
          }
        ]
      },
      {
        title: '',
        answer: 'counselling',
        fields: [
          {
            id: 'parcels',
            label: 'Name any two services offered by the EWP',
            type: 'radio',
            required: true,
            options: [
              {
                value: 'legal',
                label: 'Legal representation & labour law advice'
              },
              {
                value: 'counselling',
                label: 'Counselling services & Financial services'
              }
            ]
          }
        ]
      },
      {
        title: '',
        answer: 'no',
        fields: [
          {
            id: 'parcels',
            label: 'Are the EWP services only available in English?',
            type: 'radio',
            required: true,
            options: [
              {
                value: 'yes',
                label: 'yes'
              },
              {
                value: 'no',
                label: 'no'
              }
            ]
          }
        ]
      },
      {
        title: '',
        answer: 'yes',
        fields: [
          {
            id: 'parcels',
            label: 'Can managers also make use of the EWP Services?',
            type: 'radio',
            required: true,
            options: [
              {
                value: 'yes',
                label: 'yes'
              },
              {
                value: 'no',
                label: 'no'
              }
            ]
          }
        ]
      },
      {
        title: '',
        answer: 'no',
        fields: [
          {
            id: 'parcels',
            label:
              'Is there a cost involved with either joining or using the services offered by the EWP?',
            type: 'radio',
            required: true,
            options: [
              {
                value: 'yes',
                label: 'yes'
              },
              {
                value: 'no',
                label: 'no'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    name: 'engen',
    id: 11,
    questions: [
      {
        title: 'Your Details',
        fields: [
          {
            id: 'name',
            label: 'Name',
            type: 'text',
            required: true,
            options: []
          },
          {
            id: 'surname',
            label: 'Surname',
            type: 'text',
            required: true,
            options: []
          },
          {
            id: 'phone',
            label: 'Cell number',
            type: 'text',
            required: true
          },
          {
            id: 'clubcard',
            label: 'ClubCard Number',
            type: 'text',
            required: false
          },
          {
            id: 'location',
            label: 'Location',
            type: 'select',
            options: [
              'Select location...',
              'Clicks Head Office Cape Town',
              'Engen Head Office Cape Town',
              'Engen Regional Office Westville',
              'Engen Regional Office Sandton'
            ],
            required: false
          }
        ]
      },
      {
        title: '',
        answer: '1L = 1 point',
        fields: [
          {
            id: 'dates',
            label: 'From February onwards, what is the always-on ClubCard earn rate at Engen?',
            type: 'radio',
            required: true,
            options: [
              {
                value: '1L = 3 points',
                label: '1L = 3 points'
              },
              {
                value: '1L = 2 points',
                label: '1L = 2 points'
              },
              {
                value: '1L = 1 point',
                label: '1L = 1 point'
              },
              {
                value: '1L = 10 points',
                label: '1L = 10 points'
              }
            ]
          }
        ]
      }
    ]
  }
]
